import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {BrowserModule, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

import { AppLogService } from '../../include/app-log/app-log.service';
import { DefaultCoverPhoto } from '../../include/app-settings/app-settings.module';

@Component({
    selector: 'media-dialog',
    templateUrl: './media-dialog.page.html',
    styleUrls: ['./media-dialog.page.scss'],
})
export class MediaDialogPage implements OnInit {

    @Input() uri: string = '';
    @Input() mime: string = '';
    @Input() title: string = '';

    imageSrc: string;

    videoSrc: string;
    videoPoster = DefaultCoverPhoto;

    youTubeSrc: SafeResourceUrl;
    audioSrc: string;

    constructor(
        private log: AppLogService,
        private sanitizer: DomSanitizer,
        private modal: ModalController,
    ) { }

    ngOnInit() {
    }

    ionViewWillEnter() {
        this.log.trace("item title", this.title);

        if (this.mime.startsWith("image")) {
            this.imageSrc = this.uri;
        } else if (this.mime.startsWith("video")) {
            if (this.mime === 'video/youtube') {
                let id = this.uri.split('/').pop().split('.').shift();

                this.youTubeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${id}?controls=0`);
            } else {
                this.videoSrc = this.uri;
            }
        } else if (this.mime.startsWith("audio")) {
            this.audioSrc = this.uri;
        }
    }

    async close() {
        await this.modal.dismiss();
    }
}
