import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { AppLogService } from '../../include/app-log/app-log.service';

@Component({
    selector: 'app-create-dialog',
    templateUrl: './create-dialog.page.html',
    styleUrls: ['./create-dialog.page.scss'],
})
export class CreateDialogPage implements OnInit {

    constructor(
        private log: AppLogService,
        private modal: ModalController,
    ) { }

    downloadHref: string;
    target: string = '_blank';

    ngOnInit() {
        let ver = window.navigator.appVersion;

        if (ver.indexOf('iPhone') >= 0) {
            this.downloadHref = '/download-ios';
        } else if (ver.indexOf('Android') >= 0) {
            this.downloadHref = '/download-android';
        } else {
            this.downloadHref = '/start';
            this.target = '_self';
        }
    }

    close() {
        this.modal.dismiss();
    }
}

export const CssClass = 'dialog-create-tag';