import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { AppLogService } from '../../include/app-log/app-log.service';
import { AppSettingsService } from '../../include/app-settings/app-settings.service';

@Component({
    selector: 'app-video-dialog',
    templateUrl: './video-dialog.page.html',
    styleUrls: ['./video-dialog.page.scss'],
})
export class VideoDialogPage implements OnInit {

    @Input() posterLandscape: string;
    @Input() posterPortrait: string;
    @Input() videoLandscape: string;
    @Input() videoPortrait: string;

    isMobile: boolean;

    constructor(
        private log: AppLogService,
        private settings: AppSettingsService,
        private modal: ModalController,
    ) { }

    ngOnInit() {
        this.isMobile = this.settings.usingIOS || this.settings.usingAndroid;
    }

    async close() {
        await this.modal.dismiss();
    }
}
