import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-empty-tag-dialog',
    templateUrl: './empty-tag-dialog.page.html',
    styleUrls: ['./empty-tag-dialog.page.scss'],
})
export class EmptyTagDialogPage implements OnInit {

    constructor(
        private dialog: ModalController,
    ) { }

    ngOnInit() {
    }

    close() {
        this.dialog.dismiss();
    }
}

export const CssClass = 'dialog-empty-tag';