import { Directive } from '@angular/core';

@Directive({
    selector: 'video[background]',
    host: {
        'autoplay': '',
        'oncanplay': 'this.play()',
        // 'onloadedmetadata': 'this.muted = true',
    }
})
export class VideoAutoPlayDirective {

    constructor() {}

}

@Directive({
    selector: 'video[muted]',
    host: {
        'onloadedmetadata': 'this.muted = true',
    }
})
export class VideoMutedDirective {

    constructor() {}

}

