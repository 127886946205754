import { NgModule } from '@angular/core';

import { VideoAutoPlayDirective, VideoMutedDirective } from './video-auto-play/video-auto-play.directive';

@NgModule({
  declarations: [
  	VideoAutoPlayDirective,
    VideoMutedDirective,
  ],
  exports: [
  	VideoAutoPlayDirective,
    VideoMutedDirective,
  ]
})
export class DirectivesModule { }
